import baseApiProd from '@/api/baseprod';
import baseApiHom from '@/api/basehom';
import fields from '@/api/fields';
import order from '@/utils/order';

const refreshAuthProd = (auth) => baseApiProd.refreshToken(auth);
const refreshAuthHom = (auth) => baseApiHom.refreshToken(auth);

const getLocalDateTime = () => {
  const myDate = new Date().toLocaleString();

  const date = myDate.split(' ')[0];
  const time = myDate.split(' ')[1];

  const day = date.split('/')[0];
  const month = date.split('/')[1];
  const year = date.split('/')[2];

  const result = `${year}-${month}-${day}T${time}.000Z`;
  return result;
};

const ordenarPorVersao = (lista) => {
  return lista.sort((a, b) => {
    const versaoA = a.versao.split(".").map(Number);
    const versaoB = b.versao.split(".").map(Number);

    for (let i = 0; i < Math.max(versaoA.length, versaoB.length); i++) {
      const numA = versaoA[i] || 0;
      const numB = versaoB[i] || 0;

      if (numA > numB) return -1; // Mais atual
      if (numA < numB) return 1; // Mais antiga
    }

    return 0; // Se todas as partes forem iguais
  });
};

export const erros = {
  refreshAuthProd,
  order: { sortBy: 'iderro', descending: 1 },
  fields: fields.erros,
  fieldsDetalhes: fields.errosDetalhes,
  fieldsAgrupados: fields.errosAgrupados,
  fieldsAgrupadosVersao: fields.errosAgrupadosVersao,
  orderAgrupados: { sortBy: 'ocorrencias', descending: 1 },
  orderAgrupadosVersao: { sortBy: 'ocorrenciasversao', descending: 1 },
  getAll: () => baseApiProd.get('erros')
    .then(({ data }) => data),
  getAgrupados: () => baseApiProd.get('erros/agrupados')
    .then(({ data }) => data),
  getAgrupadosVersao: (versao) => baseApiProd.get(`erros/agrupados/${versao}`)
    .then(({ data }) => data),
  getByHash: (hash) => baseApiProd.get(`erros/detalhes/${hash}`)
    .then(({ data }) => data),
  getByVersao: (versao) => baseApiProd.get(`erros/${versao}`)
    .then(({ data }) => data),
  getByCliente: (cliente) => baseApiProd.get(`erros/cliente/${cliente}`)
    .then(({ data }) => data),
};

export const clientes = ({
  refreshAuthProd,
  order: { sortBy: 'idcliente', descending: 1 },
  fields: fields.clientes,
  getAll: () => baseApiProd.get('clientes')
    .then(({ data }) => data),
  getDetalhes: (cliente) => baseApiProd.get(`clientes/${cliente}`)
    .then(({ data }) => data),

  getBeforeVersion: (idversao) => baseApiProd.get(`clientes/beforeversion/${idversao}`)
    .then(({ data }) => data),

  updateStatus: (cliente, body) => baseApiProd
    .put(`clientes/${cliente}/updateStatus`, body)
    .then(({ data }) => data),
});

export const versoes = {
  refreshAuthProd,
  orderClientes: { sortBy: 'data', descending: 1 },
  order: { sortBy: 'idversao', descending: 1 },
  fields: fields.versoes,
  fieldsClientes: fields.versoesClientes,
  fieldsErros: fields.versoesErros,
  fieldsExecutaveis: fields.versoesExecutaveis,
  fieldsScripts: fields.versoesScripts,

  getAll: (params = {}) => {
    const queryParams = new URLSearchParams(params).toString();
    return baseApiProd.get(`versoes/detalhes${queryParams ? `?${queryParams}` : ''}`)
      .then(({ data }) => {
        return ordenarPorVersao(data);
      });
  },
  getDatahub: () => {
    return baseApiProd.get(`versoes/datahub`).then(({ data }) => data);
  },
  getDetalhes: (versao) => baseApiProd.get(`versoes/${versao}`)
    .then(({ data }) => data),
  getScripts: (versao) => baseApiProd.get(`versoes/scripts/${versao}`)
    .then(({ data }) => data),
  getExecutaveis: (versao) => baseApiProd.get(`versoes/executaveis/${versao}`)
    .then(({ data }) => data),
  getDisponiveis: () => baseApiProd.get('versoes/att/disponiveis')
    .then(({ data }) => data),

  alterarDisponibilidade: (versao, body) => baseApiProd
    .put(`versoes/${versao}/disponibilidade`, body)
    .then(({ data }) => data),

  getExecutaveisExternal: () => baseApiProd.get(`versoes/external`)
    .then(({ data }) => data),

  getExecutaveisExternalVersao: (versao) => baseApiProd.get(`versoes/external/${versao}`)
    .then(({ data }) => data),
};

export const authProd = {
  refreshAuthProd,
  login: async (data) => await baseApiProd.post('auth/login', data).then(({ data }) => data),
};

export const authHom = {
  refreshAuthHom,
  login: async (data) => await baseApiHom.post('auth/login', data).then(({ data }) => data),
};

export const auditoria = {
  prod: {
    refreshAuthProd,
    getAll: async (params = {}) => {
      const queryParams = new URLSearchParams(params).toString();
      const link = `versoes/detalhes${queryParams ? `?${queryParams}` : ''}`;
      // console.log(link)	
      return await baseApiProd.get(link).then(({ data }) => data)
    },
    getScripts: () => baseApiProd.get('versoes/scripts/0').then(({ data }) => data),
    getExecutaveis: () => baseApiProd.get('versoes/executaveis/0').then(({ data }) => data),
  },
  hom: {
    refreshAuthHom,
    getAll: async (params = {}) => {
      const queryParams = new URLSearchParams(params).toString();
      const link = `versoes/detalhes${queryParams ? `?${queryParams}` : ''}`;
      // console.log(link)
      return await baseApiHom.get(link).then(({ data }) => data)
    },
    getScripts: () => baseApiHom.get('versoes/scripts/0').then(({ data }) => data),
    getExecutaveis: () => baseApiHom.get('versoes/executaveis/0').then(({ data }) => data),
  },
};

export const versaoAgendamento = {
  refreshAuthProd,
  order: { sortBy: 'executado', descending: false },
  fields: fields.versaoAgendamento,

  getAll: (initDate, endDate) => baseApiProd.get(`versaoagendamento/todos?&initdate=${initDate}&enddate=${endDate}`).then(({ data }) => data),
  getExecutados: () => baseApiProd.get('versaoagendamento/executados').then(({ data }) => data),
  getPendentes: () => baseApiProd.get('versaoagendamento/naoexecutados').then(({ data }) => data),

  create: (registers) => {
    registers.createdAt = getLocalDateTime();
    return baseApiProd.post('versaoagendamento', registers)
      .then(({ data }) => data);
  },
  cancelar: (id, motivo) => {
    const data = { updatedAt: getLocalDateTime(), motivo };
    return baseApiProd.post(`versaoagendamento/${id}/cancelar`, data)
      .then(({ data }) => data);
  },
};

export const versaoLog = {
  refreshAuthProd,
  getByAgendamento: (versaoagendamento) => baseApiProd.get(`versaolog/${versaoagendamento}`)
    .then(({ data }) => data),
};

export const server = {
  refreshAuthProd,
  getClientes: () => baseApiProd.get('server/logs/clientes').then(({ data }) => data),

  getLogs: (hash, query) => {
    let url = '';

    if (query) {
      url += '?';

      for (const item in query) {
        if (!url.endsWith('?')) { url += '&'; }

        url += `${item}=${query[item]}`;
      }
    }

    return baseApiProd.get(`server/${hash}/logs${url}`).then(({ data }) => data);
  },
  getStatus: (hash, query) => {
    let url = '';

    if (query) {
      url += '?';

      for (const item in query) {
        if (!url.endsWith('?')) { url += '&'; }

        url += `${item}=${query[item]}`;
      }
    }

    return baseApiProd.get(`server/status${url}`).then(({ data }) => data);
  },
};

export default {
  fields,
  refreshAuthProd,
  refreshAuthHom,
  authProd,
  authHom,
  auditoria,
  erros,
  clientes,
  versoes,
  versaoAgendamento,
  server,
};
